<template>
  <div>
    <c-tab
      ref="hazopActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :popupParam.sync="popupParam"
          :traningEdu.sync="traningEdu"
          :attachTeachingMaterialsInfo.sync="attachTeachingMaterialsInfo"
          :isOld="isOld"
          :disabled="disabled"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'fireFightingTraningEduPlanTab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    traningEdu: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: null,  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenarios: [],
      }),
    },
    attachTeachingMaterialsInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_EDU_TEACHING_MATERIALS',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      editable: true,
      listUrl: '',
    };
  },
  computed: {
    tabItems() {
      let items = [
        { name: 'info', icon: 'info', label: '기본정보', key: uid(), component: () => import(`${'./fireFightingTraningEduPlanInfo.vue'}`) },
        { name: 'user', icon: 'person_add', label: '참석자', key: uid(), component: () => import(`${'./fireFightingTraningEduPlanUser.vue'}`), disabled: !this.isOld },
        { name: 'scenario', icon: 'format_list_numbered', label: '시나리오', key: uid(), component: () => import(`${'./fireFightingTraningEduPlanScenario.vue'}`), disabled: !this.isOld },
      ];
      return items;
    },
    tabHeight() {
      let height = this.contentHeight - 100;
      if (height < 740) {
        height = 740;
      }
      return String(height) +'px';
    },
    disabled() {
      return this.traningEdu.sopFireFightingTraningEduStepCd === 'FFTE000005' 
        || this.traningEdu.sopFireFightingTraningEduStepCd === 'FFTE000010'
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    getDetail() {
      this.$emit('getDetail')
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
